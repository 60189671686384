@media only screen and (min-width: 1440px) {
  /* #root {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
}

@media only screen and (min-width: 3800px) {
  #root {
    padding-top: 84px;
  }
}

@media only screen and (max-width: 400px) {
  .main {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 38px;
  }
}

@media only screen and (max-width: 880px) {
  .board-illust {
    display: none;
  }
}

.board-button-container {
  gap: 6px;
}
