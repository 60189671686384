@media only screen and (min-width: 1440px) {
  #root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.illustrasi {
  width: 100%;
  height: fit-content;
  max-width: 608px;
  max-height: 401px;
}

@media only screen and (max-width: 1000px) {
  .illustrasi {
    display: none;
  }
  .Login-form {
    margin-left: 0;
  }
}
