.Kelas {
  width: 100%;
}

@media only screen and (min-width: 1440px) {
  #root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Kelas {
    width: 100%;
    /* max-width: 1440px; */
  }
}

.info {
  gap: 16px;
}

.info-kanan {
  gap: 8px;
}

.mahasiswa-header-kiri {
  gap: 4px;
}
